<template>
  <nav
    class="fixed top-0 h-14 bg-foundation max-w-full w-full shadow hover:shadow-md transition z-20"
  >
    <div class="px-4 flex items-center h-full">
      <HeaderLogoBlock class="mr-0" />
      <div class="flex items-center truncate">
        <HeaderBreadcrumb
          v-for="item in breadcrumbItems"
          :key="breadcrumbKey(item)"
          :breadcrumb="item"
        />
      </div>
      <div class="grow" />
      <PortalTarget name="primary-actions"></PortalTarget>
      <HeaderNavUserMenu v-if="core.isLoggedIn" class="shrink-0" />
      <FormButton v-else size="lg" @click="startAuthFlow">
        Login with Speckle
      </FormButton>
    </div>
  </nav>
</template>
<script setup lang="ts">
import { breadcrumbKey } from '~/composables/breadcrumbs'
import { useTriggerLogin } from '~/lib/frontend/auth/composables/auth'
import { useCoreStore } from '~/lib/frontend/core/stores/core'
const core = useCoreStore()
const startAuthFlow = useTriggerLogin()

const { allItems: breadcrumbItems } = useCurrentBreadcrumbsState()

onServerPrefetch(() => {
  // for some reason I have to touch breadcrumbItems here in some way (even if nothing is actually done)
  // to prevent hydration mismatches with breadcrumbs
  return Promise.resolve(breadcrumbItems.value)
})
</script>
