<template>
  <div>
    <Menu as="div" class="ml-2 flex items-center">
      <MenuButton v-slot="{ open: userOpen }">
        <span class="sr-only">Open user menu</span>
        <UserAvatar v-if="!userOpen" size="lg" :user="core.activeUser" hover-effect />
        <UserAvatar v-else size="lg" hover-effect>
          <XMarkIcon class="w-5 h-5" />
        </UserAvatar>
      </MenuButton>
      <Transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute right-0 md:right-4 top-14 md:top-16 w-full md:w-64 origin-top-right bg-foundation outline outline-2 outline-primary-muted rounded-md shadow-lg overflow-hidden"
        >
          <MenuItem v-slot="{ active }">
            <NuxtLink
              :class="[
                active ? 'bg-foundation-focus' : '',
                'flex items-center  justify-between px-2 py-3 text-sm text-foreground cursor-pointer transition'
              ]"
              @click="onThemeClick"
            >
              {{ isDarkTheme ? 'Light Mode' : 'Dark Mode' }}
              <Icon class="w-5 h-5 mr-2" />
            </NuxtLink>
          </MenuItem>
          <MenuItem v-if="core.activeUser" v-slot="{ active }">
            <NuxtLink
              :class="[
                active ? 'bg-foundation-focus' : '',
                'flex items-center justify-between px-2 py-3 text-sm text-foreground cursor-pointer transition'
              ]"
              @click="() => (isUserInfoOpen = true)"
            >
              API & Dev Info
            </NuxtLink>
          </MenuItem>
          <MenuItem v-if="core.activeUser" v-slot="{ active }">
            <NuxtLink
              :class="[
                active ? 'bg-foundation-focus' : '',
                'flex items-center  justify-between px-2 py-3 text-sm text-danger cursor-pointer transition'
              ]"
              @click="logout"
            >
              Sign Out
              <ArrowLeftOnRectangleIcon class="w-5 h-5 mr-2" />
            </NuxtLink>
          </MenuItem>
          <MenuItem v-if="!core.activeUser" v-slot="{ active }">
            <NuxtLink
              :class="[
                active ? 'bg-foundation-focus' : '',
                'flex items-center  justify-between px-2 py-3 text-sm text-primary cursor-pointer transition'
              ]"
              @click="login"
            >
              Sign In
              <ArrowRightOnRectangleIcon class="w-5 h-5 mr-2" />
            </NuxtLink>
          </MenuItem>
          <MenuItem v-if="appVersion">
            <div class="px-2 pb-1 text-tiny text-foreground-2">
              Version {{ appVersion }}
            </div>
          </MenuItem>
        </MenuItems>
      </Transition>
    </Menu>
    <UserInfoDialog v-model:open="isUserInfoOpen" />
  </div>
</template>
<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  MoonIcon,
  SunIcon,
  XMarkIcon
} from '@heroicons/vue/24/solid'
import { useTriggerLogin, useTriggerLogout } from '~/lib/frontend/auth/composables/auth'
import { AppTheme, useTheme } from '~/lib/frontend/core/composables/theme'
import { useCoreStore } from '~/lib/frontend/core/stores/core'

const core = useCoreStore()
const login = useTriggerLogin()
const { isDarkTheme, setTheme } = useTheme()
const {
  public: { appVersion }
} = useRuntimeConfig()

const isUserInfoOpen = ref(false)

const Icon = computed(() => (isDarkTheme.value ? SunIcon : MoonIcon))

const onThemeClick = () => {
  if (isDarkTheme.value) {
    setTheme(AppTheme.Light)
  } else {
    setTheme(AppTheme.Dark)
  }
}

const logout = await useTriggerLogout()
</script>
