<template>
  <div class="flex items-center grow relative">
    <FormTextInput
      name="key"
      :model-value="value"
      readonly
      input-classes="text-sm text-primary font-mono"
      wrapper-classes="shrink grow z-20"
    ></FormTextInput>
    <FormButton
      v-if="showCopyButton"
      text
      class="shrink-0 relative -left-1 z-10"
      :icon-left="ClipboardDocumentIcon"
      hide-text
      @click="onCopy"
    ></FormButton>
  </div>
</template>
<script setup lang="ts">
import { ClipboardDocumentIcon } from '@heroicons/vue/24/outline'
import { useClipboard } from '~/lib/frontend/common/composables/dom'

const props = withDefaults(
  defineProps<{
    value: string
    showCopyButton?: boolean
  }>(),
  { showCopyButton: true }
)

const { copy } = useClipboard()

const onCopy = async () => {
  await copy(props.value)
}
</script>
