<template>
  <LayoutDialog v-model:open="isOpen" max-width="md">
    <div>
      <div class="flex space-x-2 items-center mb-4">
        <div class="h4 font-bold">Dev Info</div>
      </div>
      <div v-if="core.authCookie || core.activeUser" class="space-y-4">
        <div v-if="core.authCookie">
          <div class="flex items-center space-x-2 mr-8">
            <h2>API token:</h2>
            <CommonKeyOutput :value="core.authCookie" />
          </div>
          <p>
            Interactive
            <FormButton link text :to="docsLink" external target="_blank">
              OpenAPI docs are here.
            </FormButton>
          </p>
        </div>
        <div v-if="core.activeUser">
          <div>
            <strong>Username:</strong>
            {{ core.activeUser.name }}
          </div>
          <div>
            <strong>User ID:</strong>
            {{ core.activeUser.id }}
          </div>
        </div>
      </div>
      <div v-else>
        <FormButton @click="startAuthFlow">Login with Speckle</FormButton>
      </div>
    </div>
  </LayoutDialog>
</template>
<script setup lang="ts">
import { useTriggerLogin } from '~/lib/frontend/auth/composables/auth'
import { useCoreStore } from '~/lib/frontend/core/stores/core'

const emit = defineEmits<(e: 'update:open', v: boolean) => void>()

const props = defineProps<{
  open: boolean
}>()

const core = useCoreStore()
const {
  public: { canonicalUrl }
} = useRuntimeConfig()
const startAuthFlow = useTriggerLogin()

const docsLink = computed(() =>
  new URL('/api/v1/docs', canonicalUrl as unknown as string).toString()
)

const isOpen = computed({
  get: () => props.open,
  set: (newVal) => emit('update:open', newVal)
})
</script>
