<template>
  <NuxtLink class="flex items-center shrink-0" to="/">
    <img
      class="block h-6 w-6"
      src="~~/assets/images/speckle_logo_big.png"
      alt="Speckle"
    />
    <div class="text-primary h6 mt-0 hidden font-bold leading-7 md:ml-1 md:flex">
      Automate
    </div>
  </NuxtLink>
</template>
